.container-fluid {
    padding: 0;
}
.drive-wrapper {
    /* padding-top: 15px;
    padding-bottom: 15px; */
    padding: 15px;
}
.drive-wrapper .card-default {
    border-radius: 10px;
    min-height: 510px;
    border-top-width: 3px;
    border-color: #cfdbe2;
}
.drive-wrapper .card-body {
    padding: 10px;
}

.drive-wrapper .options-section h4,
.drive-wrapper .folder-section h4 {
    font-size: 14px;
    color: #909fa7;
    text-transform: uppercase;
    font-family: GloberRegular;
    font-weight: bold;
    margin: 0;
}
.drive-wrapper .options-section .list-group .list-group-item {
    border: none;
    color: rgb(0, 31, 114);
    font-weight: 600;
    font-family: GloberRegular;
    font-size: 16px;
}
.drive-wrapper .options-section .list-group .list-group-item > i {
    margin-right: 10px;
}
