@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";

body {
    /* font-family: "GloberRegular"; */
}

.header-wrapper {
    margin: 20px auto;
}

.header-wrapper .header {
    display: flex;
    justify-content: center;
}
.header-wrapper .header-logo {
    width: 150px;
}
.header-wrapper .header-text {
    margin-left: 8px;
    color: rgb(0, 31, 114);
    font-weight: bold;
    font-size: 20px;
}

.signup-wrapper .signup-form {
    min-width: 300px;
    max-width: 300px;
    width: 100%;
    margin: 30px auto;
    text-align: center;
}

.signup-wrapper .slogan {
    font-family: "GloberRegular";
    color: #6a747b;
    font-size: 19px;
    line-height: 27px;
    margin: 0 auto 10%;
    word-wrap: break-word;
}
.signup-wrapper .form-group .form-control {
    padding: 10px 20px;
    background: #f2f3f4;
    margin-bottom: 5px;
    border-radius: 5px;
    font-family: globersemibold;
    height: 50px;
}

.signup-wrapper .buttons-wrapper .register-button {
    width: 100%;
    background: #164e99;
    border: 0;
    border-radius: 5px;
    font-family: GloberBold;
    color: #fff !important;
    height: 50px;
    margin-bottom: 25px;
}
.signup-wrapper .buttons-wrapper .forgot-button {
    background: 0 0;
    color: #6a747b !important;
    text-decoration: underline;
    font-family: "GloberRegular";
    margin-bottom: 25px;
    border: none;
}

.btn-download-pdf {
    background-color: transparent;
    border: none;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.btn-download-pdf:hover {
    background-color: rgba(0, 0, 0, 0.1);
}