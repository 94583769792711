body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@charset "iso-8859-1";
/* CSS Document */

/* @FONT-FACE
******************************************************************/

@font-face {
    font-family: "GloberBook";
    src: url(/static/media/glober_book-webfont.7d621592.eot);
    src: url(/static/media/glober_book-webfont.7d621592.eot?#iefix) format("embedded-opentype"),
        url(/static/media/glober_book-webfont.734ab8b8.woff) format("woff"),
        url(/static/media/glober_book-webfont.0956ab63.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GloberRegular";
    src: url(/static/media/glober_regular-webfont.c6bc7e7c.eot);
    src: url(/static/media/glober_regular-webfont.c6bc7e7c.eot?#iefix) format("embedded-opentype"),
        url(/static/media/glober_regular-webfont.421ea081.woff) format("woff"),
        url(/static/media/glober_regular-webfont.732a9cbf.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GloberBold";
    src: url(/static/media/glober_bold-webfont.1c755d2f.eot);
    src: url(/static/media/glober_bold-webfont.1c755d2f.eot?#iefix) format("embedded-opentype"),
        url(/static/media/glober_bold-webfont.15602b87.woff) format("woff"),
        url(/static/media/glober_bold-webfont.52699a3c.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: globersemibold;
    src: url(/static/media/fontfabric_-_glober_semibold-webfont.b6eca1a4.woff2) format("woff2"),
        url(/static/media/fontfabric_-_glober_semibold-webfont.faab5fbd.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "GloberLight";
    src: url(/static/media/glober_light-webfont.44340fca.eot);
    src: url(/static/media/glober_light-webfont.44340fca.eot?#iefix) format("embedded-opentype"),
        url(/static/media/glober_light-webfont.9d56034c.woff) format("woff"),
        url(/static/media/glober_light-webfont.63fd72bd.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "icomoon";
    src: url(/static/media/icomoon.44df401e.eot);
    src: url(/static/media/icomoon.44df401e.eot?#iefix) format("embedded-opentype"), url(/static/media/icomoon.4fbd31dd.woff) format("woff"),
        url(/static/media/icomoon.928054c4.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
/* EDITOR PROPERTIES - PLEASE DON'T DELETE THIS LINE TO AVOID DUPLICATE PROPERTIES */

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgb(34, 34, 34, 0.5);
}
.loader-wrapper .spinner-border {
  width: 3rem;
  height: 3rem;
}
.loader-wrapper .loader-demo {
  height: 150px;
  padding-top: 50px;
  text-align: center;
}
.loader-wrapper .loader-demo > div {
  display: inline-block;
}
.loader-wrapper .loader-demo.loader-demo-sk {
  padding-top: 0;
}

.loader-wrapper .card-body {
  padding: 0;
}

.profile-wrapper .form-control input[type="text"] {
  border: 1px solid #dde6e9;
}

.snackbar-wrp {
  max-width: 600px;
}

body {
    /* font-family: "GloberRegular"; */
}

.header-wrapper {
    margin: 20px auto;
}

.header-wrapper .header {
    display: flex;
    justify-content: center;
}
.header-wrapper .header-logo {
    width: 150px;
}
.header-wrapper .header-text {
    margin-left: 8px;
    color: rgb(0, 31, 114);
    font-weight: bold;
    font-size: 20px;
}

.signup-wrapper .signup-form {
    min-width: 300px;
    max-width: 300px;
    width: 100%;
    margin: 30px auto;
    text-align: center;
}

.signup-wrapper .slogan {
    font-family: "GloberRegular";
    color: #6a747b;
    font-size: 19px;
    line-height: 27px;
    margin: 0 auto 10%;
    word-wrap: break-word;
}
.signup-wrapper .form-group .form-control {
    padding: 10px 20px;
    background: #f2f3f4;
    margin-bottom: 5px;
    border-radius: 5px;
    font-family: globersemibold;
    height: 50px;
}

.signup-wrapper .buttons-wrapper .register-button {
    width: 100%;
    background: #164e99;
    border: 0;
    border-radius: 5px;
    font-family: GloberBold;
    color: #fff !important;
    height: 50px;
    margin-bottom: 25px;
}
.signup-wrapper .buttons-wrapper .forgot-button {
    background: 0 0;
    color: #6a747b !important;
    text-decoration: underline;
    font-family: "GloberRegular";
    margin-bottom: 25px;
    border: none;
}

.btn-download-pdf {
    background-color: transparent;
    border: none;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.btn-download-pdf:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.container-fluid {
    padding: 0;
}
.drive-wrapper {
    /* padding-top: 15px;
    padding-bottom: 15px; */
    padding: 15px;
}
.drive-wrapper .card-default {
    border-radius: 10px;
    min-height: 510px;
    border-top-width: 3px;
    border-color: #cfdbe2;
}
.drive-wrapper .card-body {
    padding: 10px;
}

.drive-wrapper .options-section h4,
.drive-wrapper .folder-section h4 {
    font-size: 14px;
    color: #909fa7;
    text-transform: uppercase;
    font-family: GloberRegular;
    font-weight: bold;
    margin: 0;
}
.drive-wrapper .options-section .list-group .list-group-item {
    border: none;
    color: rgb(0, 31, 114);
    font-weight: 600;
    font-family: GloberRegular;
    font-size: 16px;
}
.drive-wrapper .options-section .list-group .list-group-item > i {
    margin-right: 10px;
}

.mobile-wrapper .mobile-header .header-wrapper {
    height: 60px;
    background-color: #00bbd1;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 50px; */
    /* padding-right: 5px; */
    margin: 0;
}
.mobile-wrapper .mobile-header .header-wrapper .file-selected {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}
.mobile-wrapper .mobile-header .back-nav {
    width: 40px;
    cursor: pointer;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-wrapper .mobile-header .header-title {
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.mobile-wrapper .mobile-header .file-selected .header-title {
    text-align: center;
}

.mobile-wrapper .mobile-content {
    padding: 15px 0;
}

.mobile-wrapper .mobile-header .appbar-wrapper {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 15px; */
}

.mobile-wrapper .mobile-header .appbar-wrapper .logo-wrapper .header-logo {
    width: 150px;
}
.mobile-wrapper .mobile-header .appbar-wrapper .logo-wrapper .header-text {
    margin-left: 8px;
    color: rgb(0, 31, 114);
    font-weight: bold;
    font-size: 20px;
}

.mobile-wrapper .mobile-header .appbar-wrapper .btn-logout {
    height: 30px;
    font-size: 12px;
    color: #003087;
    border: 1px solid #003087;
    border-radius: 2px;
    font-weight: 500;
}

.category_section__kteFB {
    margin-bottom: 20px;
}
.category_section_title__2jW2r {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #596671;
    border-bottom: 1px solid #00bbd1;
    padding-bottom: 7px;
    line-height: 16px;
}
.category_section__kteFB .category_type_title__1hE28 {
    color: #00bbd1;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.fileslist_file_item__4iXtt {
    display: flex;
    align-items: center;
    cursor: pointer;
}
/* 
 .file_item.active {
    background-color: #5d9cec;
    color: #fff;
} */
.fileslist_empty_content__15U4S {
    font-size: 10px;
}
/* .file-item.active:hover {
    background-color: #5d9cec;
} */
.fileslist_file_item__4iXtt:hover {
    background-color: #c0d0d3;
}
.fileslist_file_icon__1z7pj {
    margin: 10px;
    position: relative;
    color: #596671;
}

.fileslist_need_viwed__3PtrG {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 7px 7px;
    border-radius: 50%;
    background: red;
    color: white;
}
.fileslist_file_item__4iXtt p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.fileslist_file_content__1wk1G {
    width: 100%;
    display: block;
    overflow: hidden;
    color: #596671;
}

.fileslist_title__Z8eN1 {
    font-size: 12px;
    line-height: 14px;
}
.fileslist_created_at__vJRRB {
    font-size: 11px;
    line-height: 13px;
}

.structure {
    padding: 15px 0;
}
.files-list {
    margin: 5px 0;
}
.files-list .file-item {
    border: none;
    font-family: GloberRegular;
    font-weight: 600;
    font-size: 16px;
    color: #3a3f51;
    transition: background-color 0.3s ease;
    border-radius: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}
.files-list .file-item:hover {
    background-color: #c0d0d3;
}
.files-list .file-item.active {
    background-color: #5d9cec;
    color: #fff;
}

.doctype-panels .panel-heading {
    padding: 0.75rem 1.25rem 0.75rem 0;
    transition: background-color 0.3s ease;
    border-radius: 0.25rem;
    font-family: GloberRegular;
    font-weight: 600;
    font-size: 16px;
    color: rgb(0, 31, 114);
    cursor: pointer;
}
.doctype-panels .panel-heading:hover {
    background-color: #c0d0d3;
}
.doctype-panels .panel-heading .panel-title {
    width: 100%;
    display: block;
}

.doctype-panels {
    margin-left: 10px;
}

