.section {
    margin-bottom: 20px;
}
.section_title {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #596671;
    border-bottom: 1px solid #00bbd1;
    padding-bottom: 7px;
    line-height: 16px;
}
.section .type_title {
    color: #00bbd1;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
