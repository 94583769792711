.mobile-wrapper .mobile-header .header-wrapper {
    height: 60px;
    background-color: #00bbd1;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 50px; */
    /* padding-right: 5px; */
    margin: 0;
}
.mobile-wrapper .mobile-header .header-wrapper .file-selected {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}
.mobile-wrapper .mobile-header .back-nav {
    width: 40px;
    cursor: pointer;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-wrapper .mobile-header .header-title {
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.mobile-wrapper .mobile-header .file-selected .header-title {
    text-align: center;
}

.mobile-wrapper .mobile-content {
    padding: 15px 0;
}

.mobile-wrapper .mobile-header .appbar-wrapper {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 15px; */
}

.mobile-wrapper .mobile-header .appbar-wrapper .logo-wrapper .header-logo {
    width: 150px;
}
.mobile-wrapper .mobile-header .appbar-wrapper .logo-wrapper .header-text {
    margin-left: 8px;
    color: rgb(0, 31, 114);
    font-weight: bold;
    font-size: 20px;
}

.mobile-wrapper .mobile-header .appbar-wrapper .btn-logout {
    height: 30px;
    font-size: 12px;
    color: #003087;
    border: 1px solid #003087;
    border-radius: 2px;
    font-weight: 500;
}
