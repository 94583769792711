@charset "iso-8859-1";
/* CSS Document */

/* @FONT-FACE
******************************************************************/

@font-face {
    font-family: "GloberBook";
    src: url("../fonts/glober_book-webfont.eot");
    src: url("../fonts/glober_book-webfont.eot?#iefix") format("embedded-opentype"),
        url("../fonts/glober_book-webfont.woff") format("woff"),
        url("../fonts/glober_book-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GloberRegular";
    src: url("../fonts/glober_regular-webfont.eot");
    src: url("../fonts/glober_regular-webfont.eot?#iefix") format("embedded-opentype"),
        url("../fonts/glober_regular-webfont.woff") format("woff"),
        url("../fonts/glober_regular-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GloberBold";
    src: url("../fonts/glober_bold-webfont.eot");
    src: url("../fonts/glober_bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("../fonts/glober_bold-webfont.woff") format("woff"),
        url("../fonts/glober_bold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: globersemibold;
    src: url("../fonts/glober/fontfabric_-_glober_semibold-webfont.woff2") format("woff2"),
        url("../fonts/glober/fontfabric_-_glober_semibold-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "GloberLight";
    src: url("../fonts/glober_light-webfont.eot");
    src: url("../fonts/glober_light-webfont.eot?#iefix") format("embedded-opentype"),
        url("../fonts/glober_light-webfont.woff") format("woff"),
        url("../fonts/glober_light-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot");
    src: url("../fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff") format("woff"),
        url("../fonts/icomoon.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
/* EDITOR PROPERTIES - PLEASE DON'T DELETE THIS LINE TO AVOID DUPLICATE PROPERTIES */
