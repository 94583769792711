.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgb(34, 34, 34, 0.5);
}
.loader-wrapper .spinner-border {
  width: 3rem;
  height: 3rem;
}
.loader-wrapper .loader-demo {
  height: 150px;
  padding-top: 50px;
  text-align: center;
}
.loader-wrapper .loader-demo > div {
  display: inline-block;
}
.loader-wrapper .loader-demo.loader-demo-sk {
  padding-top: 0;
}

.loader-wrapper .card-body {
  padding: 0;
}

.profile-wrapper .form-control input[type="text"] {
  border: 1px solid #dde6e9;
}

.snackbar-wrp {
  max-width: 600px;
}
