.structure {
    padding: 15px 0;
}
.files-list {
    margin: 5px 0;
}
.files-list .file-item {
    border: none;
    font-family: GloberRegular;
    font-weight: 600;
    font-size: 16px;
    color: #3a3f51;
    transition: background-color 0.3s ease;
    border-radius: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}
.files-list .file-item:hover {
    background-color: #c0d0d3;
}
.files-list .file-item.active {
    background-color: #5d9cec;
    color: #fff;
}

.doctype-panels .panel-heading {
    padding: 0.75rem 1.25rem 0.75rem 0;
    transition: background-color 0.3s ease;
    border-radius: 0.25rem;
    font-family: GloberRegular;
    font-weight: 600;
    font-size: 16px;
    color: rgb(0, 31, 114);
    cursor: pointer;
}
.doctype-panels .panel-heading:hover {
    background-color: #c0d0d3;
}
.doctype-panels .panel-heading .panel-title {
    width: 100%;
    display: block;
}

.doctype-panels {
    margin-left: 10px;
}
