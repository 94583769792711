.file_item {
    display: flex;
    align-items: center;
    cursor: pointer;
}
/* 
 .file_item.active {
    background-color: #5d9cec;
    color: #fff;
} */
.empty_content {
    font-size: 10px;
}
/* .file-item.active:hover {
    background-color: #5d9cec;
} */
.file_item:hover {
    background-color: #c0d0d3;
}
.file_icon {
    margin: 10px;
    position: relative;
    color: #596671;
}

.need_viwed {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 7px 7px;
    border-radius: 50%;
    background: red;
    color: white;
}
.file_item p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.file_content {
    width: 100%;
    display: block;
    overflow: hidden;
    color: #596671;
}

.title {
    font-size: 12px;
    line-height: 14px;
}
.created_at {
    font-size: 11px;
    line-height: 13px;
}
